
import styled from "styled-components";
import { createGlobalStyle } from 'styled-components';

const GetStartedProductGlobel = createGlobalStyle`
&
    p {
        font-size: 21px !important;
    }
    .main_product_page_class{
        // padding-top: 50px;
        margin-top: 169px;

        @media only screen and (max-width: 1280px) {
            margin-top: 150px;
        }

        @media only screen and (max-width: 767px) {
            margin-top: 90px;
        }


        .first_content_section {
            background: rgb(94,178,186);
            background: linear-gradient(90deg, rgba(94,178,186,1) 0%, rgba(94,178,186,1) 25%, rgba(94,178,186,1) 100%);;
            padding: 50px 0px;
            @media (max-width: 991px){
                padding: 40px 15px;
            }
        }
        .color {
            background: linear-gradient(90deg, rgb(228, 6, 133) 0%, rgb(189, 27, 134) 21%, rgb(99, 46, 138) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            @media (max-width: 767px){
                margin-bottom: 5px;
            }
        }

        .main-section-landing {
            padding: 60px 0;
            background-repeat: repeat;
            background-size: cover;
            background-position: top;

            @media only screen and (max-width: 991px) {
                padding: 50px 0 30px;
            }

            .container {
                max-width: 1000px;

                .row {
                    @media only screen and (max-width: 991px) {
                        flex-wrap: wrap;
                    }
                }
            }
        }
    }

`;
export const GetStartedFirstStyle = styled.div` 
.fade-in {
    transition: opacity 2s ease;
}
.fade-out {
    opacity: 0;
    transition: opacity 2s ease;
}
           .firstSection_getStarted {
        display: flex;
        justify-content: center;
        gap: 70px;
        align-items: center;
        /* width: 100%;
        max-width: 900px; */
        @media (max-width: 991px){
            gap:50px;
            padding: 0 15px;
            flex-wrap: wrap;
            & > div {
                max-width: calc(100%/2);
                width: 100%;
                @media (max-width: 767px){ 
                     max-width: 100%; 
                }
            }
        }
     
        .twoHeading {
            h2 { 
                span {
                    font-size: 41px;
                    line-height: 49px;
                    font-family: var(--inte-BD);
                    @media (max-width: 991px){
                        font-size: 34px;
                    }
                    @media (max-width: 767px){
                        font-size: 28px;
                    }
                }
                text-transform: uppercase;
                margin: 0;
                color: #fff;
                text-align: center;
                @media (max-width: 991px){
                    font-size: 34px;
                }
            }
            p{
                border: 1px solid #fff;
                margin: 33px 0 25px 0;
                @media (max-width: 767px){
                    margin: 25px 0;
                    }
            }
            z-index: 1;
        }
        @media (max-width: 767px){
            gap: 40px;
        }
    }
    .contents {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        width: 100%;
        max-width: 500px;
        position: relative;
        z-index: 1;
        @media (max-width: 767px){
            row-gap: 15px;
        }
        p {
            font-size: 32px !important;
            line-height: 31px;
            color: #fff;
            @media (max-width: 991px){
                font-size: 24px !important;
            }
            @media (max-width: 767px){
                font-size: 21px !important;
                line-height: normal;
                color: #fff;
                margin-bottom: 0;
                text-align: center;
            }
            strong {
                color: #C20098;
                border-bottom: 3px solid #fff;
            }
            span {
                text-transform: uppercase;
                font-size: 34px;
                line-height: 49px;
                color: #fff;
                font-family: var(--inte-BD);
                margin-top: 0; 
                @media (max-width: 767px){
                    font-size: 26px;
                     line-height: normal;
                }
            }
        }
    }
`;
export const GetLandingPageStyle = styled.div`
/* img{
    width: 100%;
    max-width: 500px;
} */

.video_sec { 
        box-shadow: 0 0 12px rgb(0 0 0 / 50%);  
        max-width: 900px;
        /* margin-top: 200px; */
        /* height: 506px; */
        /* margin: 200px auto 0; */
        position: relative;
        z-index: 1;
        overflow: hidden;
        @media (max-width: 991px){
        height: 430px;
        max-width: max-content;
        }
        @media (max-width: 767px){
            height: auto;
            max-width: calc(100% - 40px);
        }
            iframe{ 
            position: absolute;
            top: 50%;
            transform: translate(-50% , -50%);
            left: 50%;
            width: 100vw;
            height: 100%;   
            @media (max-width: 767px){
                width: 100%;
                height: 100%;
            }
        }
        & > img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover; 
                -webkit-animation: seconds 3.0s forwards;
                -webkit-animation-iteration-count: 1;
                -webkit-animation-delay: 2s;
                animation: seconds 1.0s forwards;
                animation-iteration-count: 1;
                animation-delay: 2s;
                position: relative;
            }
            @-webkit-keyframes seconds {
            0% {
                opacity: 1;
                visibility:visible;
            }
            100% {
                opacity: 0;
                visibility:hidden;
            }
            }
            @keyframes seconds {
                0% {
                opacity: 1;
                visibility:visible;
            }
            100% {
                opacity: 0;
                visibility:hidden;
            }
        }
    }
h2.heading-sec {
    color: #000;
}
.main-section-landing {
    padding: 60px 0;

    .media-wrap {
        width: calc(100%/2);
        padding: 0 15px;

        @media only screen and (max-width: 991px) {
            width: 100%;
        }

        .image-section, .video-section {
            margin-bottom: 30px;

            img {
                width: 100%;
            }
        }

        .video-section {
            margin: 0;
            position:relative;

            .youtube-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 60px; 
                height: 60px;
                cursor: pointer;
                z-index: 10;
            
               @media only screen and (max-width: 991px) {
                width: 100px; 
                height: 100px;
                }

                 @media only screen and (max-width: 768px) {
                width: 70px; 
                height: 70px;
              }

               @media only screen and (max-width: 480px) {
                width: 70px; 
                height: 70px;
              }

              @media only screen and (max-width: 360px) {
                 width: 50px; 
                height: 50px;
              }

            }
        }
    }

    .media-content-wrap {
        width: calc(100%/2);
        padding: 0 15px;
    }
}

`;
export const GetStartedSecondStyle = styled.div`
    margin-bottom: 40px;
    .getStartedSecond_first{
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;  
            @media (max-width: 767px){
                padding: 0 15px;
            }
        .background_cc {
            background: var(--green);
            padding: 13px 40px;
        }
        .titleAndTextBox{
            h2 { 
                font-size:32px;
                color:#000;
                @media (max-width: 767px) {
                    font-size: 24px;
                }
            }
            p { font-size: 28px !important; color: #000; 
                @media (max-width: 767px) {
                    font-size: 24px !important;
                }
            
            }
            margin-top: 25px;
        }
        h2{
            &:first-child {

            }
        }
        .text_boxes {
            display: flex;
            gap: 30px;
            width: 100%;
            justify-content: center;
            margin: 20px 0 50px 0;
            @media (max-width: 767px) {
                flex-wrap: wrap;
            }
            div {
                width: 100%;
                max-width: 230px;
                background: var(--green);
                padding: 16px;
                border-radius: 30px;
                @media (max-width: 767px) {
                max-width: 300px;
                border-radius: 20px;
                }
                p {
                    color: #fff;
                }
            }
        }
    }
    .getStartedSecond_second {
        display: flex;
        justify-content: center;
        gap: 80px;
        position: relative;
        z-index: 1;
        @media (max-width: 767px) {
            gap: 30px;
            flex-wrap: wrap;
             padding: 0 15px;
        }
        .first {
            width: 100%;
            max-width: 350px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            .include_section {
                p {
                    width: 100%;
                    max-width: 264px;
                    margin: 0 auto;
                    color: #000;
                    @media (max-width: 767px){
                        max-width: 100%;  
                    }
                }
                h3 {
                    font-family: var(--inte-BD);
                    color: #000;
                    font-size: 22px; 
                   @media (max-width: 767px){ 
                    margin-top: 10px;
                   }
                }
            } 
            .image_text_one {
                .image {
                    position: relative;
                    img {
                        width: 100%;
                    }
                }
            }

            .price {
                text-align: center; 
                font-size: 65px;
                font-weight: 600;
                color: #000000; 
                line-height: normal;
                @media(max-width: 767px) {
                    font-size: 40px;
                    margin-left: -10px;
                    margin-top: 10px;
                }
            }
            button {
                text-align: center;
                margin-top: 20px;
                width: 100%;
                max-width: 220px;
                border-radius: 34px;
                padding: 7px;
                font-size: 25px;
                text-transform: uppercase;
                background: linear-gradient(90deg,rgb(228,6,133) 0%,rgb(189,27,134) 21%,rgb(99,46,138) 100%);
                color: #fff;
            }
        }
        .second {
            .content_one {
                width: 100%;
                max-width: 370px;
                color: #000;
                @media (max-width: 767px){
                    text-align: center;
                    p{
                        font-size: 18px !important;
                        line-height: 1.5;
                    }
                }
            }
            .content_two {
                h3 {
                    font-weight: 600;
                    line-height: 27px;
                    letter-spacing: 1px;
                    font-size: 22px;
                    color: #000;
                    @media (max-width: 767px){
                            font-size: 20px;
                        }
                    span{
                        text-decoration: underline;
                    }
                }
                ul {
                    margin-left: 20px;
                    li {
                        font-size: 21px; 
                        color: #000;
                        @media (max-width: 767px){
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
    img.bonus-image {
    width: 100%;
    max-width: 132px;
    position: absolute;
    top: 24px;
    right: 266px;
}
`;

export const GetStartedThirdStyle = styled.div`
.getStartedThird_section {
    padding: 100px 15px;
    text-align: center;
    background: linear-gradient(90deg, rgba(31,161,207,1) 0%, rgba(31,161,207,1) 29%, rgba(36,158,138,1) 55%);
    @media (max-width: 767px){
        padding: 30px 0; 
    }
    & >.container { 
        background: url(/images/bg-tube.png) no-repeat;
        background-size: contain; 
        @media (max-width: 1199px){
            background-size: 430px;
            background-position-y: center;
        }
    }
     &>* {
        position: relative;
        z-index: 1;
    }
    .container {
        display: flex;
        justify-content: center;
        flex-direction: column;

        @media (max-width: 767px){
            flex-wrap: wrap;
        }

        &::before, &::after {
            display: none;
        }

        .fourth_second_container {
            .image_and_icons_section {
                justify-content: flex-start;
            }
        }

        .image_and_icons_section {
            display: flex;    
            column-gap: 40px; 
            justify-content: center;

            @media only screen and (max-width: 1440px){
                column-gap: 30px; 
            }

            @media (max-width: 1199px){
                transform: translateX(0px);
                flex-wrap: wrap;
                /* flex-direction: column-reverse; */
                flex-direction: column;
            }

            .precision.nutrition {
                width: 100%;
                max-width: 400px;
                /* flex: 1; */
                /* transform: translateY(-80px); */
                /* margin-top: 94px; */

                @media only screen and (max-width: 1440px){
                    max-width: 300px;
                }

                @media (max-width: 1199px){
                    max-width: 100%;
                    transform: translateX(0px);
                    order: 2;
                    margin-top: 0px;
                }

                h3 {
                    @media only screen and (max-width: 1199px){
                        text-align: center;
                    }
                }
                .list-genetics {
                    @media only screen and (max-width: 1199px){ 
                        margin-bottom: 30px;
                    }

                    ul {
                        @media only screen and (max-width: 1199px){
                            max-width: 350px;
                            margin: auto;
                        }

                        li {

                            @media only screen and (max-width: 1399px){
                                font-size: 20px;
                                padding-left: 24px;
                            }
                        }
                    }
                }
            }
            .capsule {
                width: calc(100% - 800px - 80px);

                @media only screen and (max-width: 1440px){
                    width: calc(100% - 600px - 60px);
                }

                @media only screen and (max-width: 1199px){
                    width: 100%;
                }

                img {
                    width: 100%;
                }
            }
            .right-product {
                /* max-width: calc(100% - 500px); */
                max-width: 400px;
                width: 100%;
                display: flex;
                /* align-items: center; */
                margin-top: 94px;

                @media only screen and (max-width: 1440px){
                    max-width: 300px;
                }

                @media (max-width: 1199px){
                    max-width: 100%;
                    order: 3;
                    margin-top: 30px;
                    justify-content: center;
                }
                @media (max-width: 1024px){
                    /* order: 0; */
                    margin-top: 30px;                 
                }

                .personalized_icons {
                    @media only screen and (max-width:1199px){
                        display: flex;
                        justify-content: center;
                    }

                    .iconContainer {
                        .small_image_section {
                            width: 70px;
                        }
                    }
                }
                
            }
            .image_personalized_section {
                gap: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                width: calc(100% - 800px - 80px);

                @media only screen and (max-width: 1440px){
                    width: calc(100% - 600px - 60px);
                }

                @media only screen and (max-width:1199px){
                    order: 1;
                    width: 100%;
                }
                .select-product {
                    display: block;
                    width: 100%;

                    @media only screen and (max-width:1199px){
                        width: auto;
                    }

                    select {
                        display: block;
                        width: 100%;
                        border: unset;
                        padding: 8px 10px;
                        border-radius: 15px;
                        @media (max-width: 767px){
                        width: 100%; 
                    }
                    }
                }
                @media (max-width: 1024px){ 
                            order: 1;
                        }
                .image_section {
                    max-width: 450px;
                    img {
                        width: 100%;
                    }
                    @media (max-width: 767px){
                        max-width: 100%;
                       img{
                        max-width: 80%;
                        transform: translateX(10px);
                       }
                    }
                } 
                p {
                    color: #fff; 
                    @media (max-width: 767px){
                    margin-bottom: 10px;
                    }
                }
                .headingSection{
                    h3{
                        font-family: var(--inte-BD);
                    }
                }
            }
            .personalized_icons {
                /* transform: translateY(-50px);   
                display: grid; */
                gap: 10px;
                @media (max-width: 1024px){  
                    display: flex;
                    transform: translateY(0px);   
                    gap: 10px;
                }
                .iconContainer {
                    p { 
                        margin: 0;
                        font-size: 14px !important;
                        color: #fff;
                    }
                    .small_image_section {
                        background: var(--green);
                        padding: 10px;
                        border-radius: 10px; 
                        min-height: 70px;
                        display: grid;
                        place-content: center; 
                        @media (max-width: 1024px){
                            aspect-ratio: 1/1;
                            width: 50px;
                            min-height: 50px;
                            margin: 0 auto;
                        }
                        img {
                            width: 30px;
                            height: 35px;
                        }
                    }
                }
            }
        }
    }

    .tool-heading {
        background: linear-gradient(90deg,rgb(228,6,133) 0%,rgb(189,27,134) 21%,rgb(99,46,138) 100%);
        max-width: 272px;
        margin: 0 auto;
        padding: 8px 28px; 
    }
    .tool-heading h2.color {
            color: #fff;
        }
    .lower_heading {
        font-size: 32px;
        line-height: 40px;
        @media (max-width: 991px){
            font-size: 26px;
        }
        @media (max-width: 767px){
            font-size: 22px;
            line-height: 1.5;
            padding: 0 15px;
            margin-bottom: 0;
        }
        span{
            &.blackColor {
                color: #000;
                font-family: var(--inte-BD);
            }
            &.whiteColor {
                color: #fff;
                font-family: var(--inte-BD);
            }
        }
    }

    h3 {
        font-size: 30px;
        line-height: 38px;
        color: #fff;
        font-family: var(--common-fontmd);
        padding: 20px 0 30px;
        margin: 0;

        &.formulation-data {
            padding-top: 0;
        }

        @media only screen and (max-width: 1399px) {
            font-size: 26px;
            line-height: 1.25;
            padding: 20px 0;
        }

        @media (max-width: 767px){
            font-size: 24px;
            line-height: 34px;
            padding-bottom: 10px;
        }
    }
    .contact-personalized {
        /* position: relative;
        right: 84px; */
            h2 {
            color: #fff;
            font-family: var(--common-font);
            font-weight: 600;
            font-size: 42px;
            line-height: 34px;
            margin-top: 19px;
            @media (max-width: 767px){
                font-size: 30px;
            }
        }
        h3 {
            font-size: 18px !important;  
            font-family: var(--common-font) !important;
            font-weight: 600;
            max-width: max-content;
            margin: 0 auto;
            padding: 0 17px;
        }
        .one_time {
            background-image: linear-gradient(90deg,#fbd709 0%,#e94f24 100%);    margin-bottom: 20px;
        }
        p{
            margin: 0;
        }
    } 
    h4 {
        font-size: 25px;
        font-family: var(--common-font);
        max-width: 873px;
        margin: 40px auto;
        color: #fff;
        line-height: 37px;
        @media (max-width: 767px){
            padding: 0 15px;
            line-height: 1.5;
            font-size: 21px;
            margin: 20px 0;
        }
    }
        .revolutionary-data{
            color: #fff;
            font-family: 'SFPro-Regular';
            font-size: 18px;
        }
        sup.trade-mark {
        font-size: 12px;
        vertical-align: middle;
        font-weight: 600;
        position: relative;
        right: 1px;
}
}
    button.btn-primary-product {
        text-align: center;
        background: linear-gradient(90deg,rgb(228,6,133) 0%,rgb(189,27,134) 21%,rgb(99,46,138) 100%);
        color: #fff;
        font-size: 25px;
        text-transform: uppercase;
        border-radius: 34px;
        @media (max-width: 767px){
            font-size: 20px;
        }
    }
    button.btn-primary-product[disabled]{
        background: grey;;
    }
 
.list-genetics{
    ul {
    list-style: none;
    li {
    color: #fff;
    text-align: left;
    font-size: 26px;
    position: relative;
    padding-left: 30px;
    @media (max-width: 767px){
        font-size: 21px;
    }
    :before {
    content: "\f00c";
    position: absolute;
    left: 0;
    font-family: "FontAwesome";
}
 
} 
  
 
}
p {
    text-align: left;
    font-size: 16px !important;
    color: #fff; 
    @media (max-width: 767px){
        margin-bottom: 0;
    }
}
}

.precision.nutrition h3 {text-align: left;}
.dna-test-entry a{
    color: #fff;
 }

 .calender-data img {
    width: 9%;
    max-width: 35px;
    position: relative;
    bottom: 52px;
    height: 100%;
    cursor: pointer;
}

.calender-data {
    position: relative;
    margin-top: 10px;
    z-index: 10;
}
 .calender-data img {
    width: 9%;
    top: 8px;
    max-width: 50px;
    height: 100%;
    position: relative;
    cursor: pointer;
}

.calendar-text {
    position: absolute;
    -ms-transform: translateX(-50%);
    transform: translateX(-110%);
    border-radius: 3px;
    left: 50%;
    -ms-transform: translateX(-50%);
    background-color: #f8f8f8;
    color: #000;
    font-size: 12px;
    padding: 5px;
   bottom: 24px;
    opacity: 0;
    visibility: hidden;
}
.calender-data img:hover + span.calendar-text {
    opacity: 1;
    visibility: visible;
}
`;

export const GetStartedFourthStyle = styled.div`

.getStartedfouth_first {
    background: #fff;
    text-align: center;
    padding: 60px 0;
    @media (max-width: 991px){
        padding: 40px 0 30px;
        @media (max-width: 767px){
            padding: 30px 0;
        }
        }
    &>*{
        position: relative;
        z-index: 1;
    }
    h3 {
        margin: 0;
        color: #2c3e50;
        font-size: 32px;
        line-height: 47px;
        @media (max-width: 991px){
            font-size: 28px;
        }
        @media (max-width: 767px){
            font-size: 20px;
            line-height: 1.5;
            padding: 0 15px;
        }
         &:nth-child(2) {
               margin: 20px 0 0;
               @media (max-width: 767px){
                margin: 10px 0 0;
               }
            }
            span{
                font-weight: bold;
                text-transform: uppercase;
                color: #000;
                font-family: var(--inte-BD); 
                position: relative;
                padding: 0 3px;
                transition: 0.3s ease all;
                &:hover {
                        color: #fff;
                    }
                    :before {
                    content: "";
                    background: #000;
                    position: absolute;
                    bottom: -4px;
                    left: 0;
                    width: 100%;
                    height: 3px;
                    transition: 0.3s ease all;
                    z-index: -1;
                    }
                    :hover:before {
                    height: 100%;
                }
}
            }
    }

}

.getStartedfouth_second{
    .home_fifth_section {
        position: relative;
        z-index: 1;
        padding-top: 90px;
        @media (max-width: 991px){
            padding-top: 60px;
        }
        @media (max-width: 767px){
            padding-top: 30px;
        }
        .container {
            max-width: 1440px;
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            
            .text_section {
                max-width: 820px;
                margin: 0px auto;
                padding: 0 15px;
                @media (max-width: 767px){
                    padding: 0;
                }
                .d-flex > div {
                    max-width: calc(100%/2 - 70px);
                    @media (max-width: 767px){
                        max-width: 100%;
                        text-align: center;
                    }
                }
                >p{
                    max-width: 700px;
                }
                p {
                    font-size: 25px;
                    color: rgb(0, 0, 0);
                    font-family: var(--common-font);
                    margin-bottom: 30px;
                    @media (max-width: 767px){
                     margin-bottom: 20px; 
                     text-align: center;
                    }
                }
                .d-flex {
                    gap: 30px;
                    @media (max-width: 767px){
                        flex-wrap: wrap;
                        gap: 0;
                    }
                    h2 {
                        margin-bottom: 30px;
                        font-size: 60px;
                        line-height: 70px;
                        @media (max-width: 1280px){
                            margin-bottom: 30px;
                            font-size: 50px;
                            line-height: 60px;
                        }
                        @media (max-width: 991px){
                            font-size: 40px;
                            line-height: normal;
                        } 
                        @media (max-width: 767px){ 
                            font-size: 30px;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
`;

export default GetStartedProductGlobel;