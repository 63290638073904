import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash';
import NumberFormatComp from '@Components/Common/NumberFormatComp';
import { useRouter } from 'next/router';
import HomeSectionThree from './HomeSectionThree';
import { GiCheckMark } from 'react-icons/gi';
import DialogComponent from '@Components/Common/DialogComponent';
import { useEffect } from 'react';

function HomeSectionTwo(props) {
    const [calenderData, setCalenderData] = useState(false);
    const router = useRouter();

    const DATA = [
        {
            label: "Test",
            image: "/home_teeth",
            text: "Order your kit, delivered directly to you. Swab to collect a sample of cells from inside-surface of your cheeks. Return to lab in pre-paid mailer.",
        },
        {
            label: "Discover",
            image: "/home_sec_rainbow_new-min",
            text:
                (<>

                    In approximately 21 business days, your DNA reports will be delivered electronically, revealing your unique CODE Complex™ formula and informing you on which nutrients your body needs and why.
                </>
                )
        },
        {
            label: "Supplement",
            image: "/home_sec_product_new-min",
            text:
                (<>
                    Order your DNA-personalized CODE Complex™ supplement and support your body where it says it needs it.
                </>
                )
        }
    ];

    const [hover, setHover] = useState(false);
    const onHover = () => {
        setHover(true);
        console.log('inside-the-houtver')
        setCalenderData(true);

    };

    const onLeave = () => {
        setHover(false);
        //   setCalenderData(false);
        console.log("inside-the-onleave")
    };

    const onLeaves = () => {
        setHover(false);
        setCalenderData(false);
        console.log("inside-the-onleave")
    };

    useEffect(() => {
        let originalPaddingRight = '';

        if (calenderData) {
            originalPaddingRight = document.body.style.paddingRight || '';
            const styleTag = document.createElement('style');
            styleTag.id = 'modal-styles';
            styleTag.innerHTML = `
                          body {
                              overflow: hidden !important;
                              padding-right: unset !important;
                          }
                      `;
            document.head.appendChild(styleTag);
        } else {
            document.body.style.paddingRight = originalPaddingRight;

            const existingStyleTag = document.getElementById('modal-styles');
            if (existingStyleTag) {
                existingStyleTag.remove();
            }
        }
        return () => {
            const existingStyleTag = document.getElementById('modal-styles');
            if (existingStyleTag) {
                existingStyleTag.remove();
            }
            document.body.style.overflow = '';
            document.body.style.paddingRight = originalPaddingRight;
        };
    }, [calenderData]);

    return (
        <div className='home_second_section'>
            <div className='second_section'>
                <div className='container'>
                    <h3>Getting answers to take action<br />is as easy as 1-2-3.</h3>
                    <div className='main_card_section'>
                        {_.map(DATA, ({ label, image, text }, index) => (
                            <div className='container_of_section' key={'data' + index}>
                                <h4>{label}</h4>
                                <div className='image_section'>
                                    <span><i>{index + 1}</i></span>
                                    <img src={`/images${image}.png`} />
                                </div>
                                <p>{text}</p>
                                {label === "Supplement" && (
                                    <div className='calender-data'>
                                        <img
                                            src='/images/Calender1.png'
                                            onClick={() => setCalenderData(true)}
                                            alt="Calendar"
                                            onMouseEnter={onHover}
                                            onMouseLeave={onLeave}
                                        />
                                        {/* <span className='calendar-text'>
                                                <div className='all-order-details'>
                                                    <img src='/images/calenderDetails.png' height="60" width="60"/>
                                                </div>
                                           </span> */}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <HomeSectionThree />
            {router?.pathname !== "/get_started" &&
                <>
                    <div className='white_strip'>
                        <div><span><GiCheckMark /></span> <span>Third-party tested</span> </div>
                        <div><span><GiCheckMark /></span> <span>Manufactured in an NSF Certified, cGMP Compliant and FDA Registered facility </span> </div>
                        <div><span><GiCheckMark /></span> <span>Non-GMO</span> </div>
                    </div>

                    <div className='first_section flex_class'>
                        <div className='image_section'>
                            <img src="/images/home-people.png" />
                        </div>
                    </div>
                </>
            }
            <DialogComponent opend={calenderData} handleClose={() => setCalenderData(false)} classFor="graphic_calender">
                <div className='all-order-details' onMouseLeave={onLeaves}>
                    <img src='/images/calenderDetails.png' height="60" width="60" />
                </div>
            </DialogComponent>
        </div>
    )
}

HomeSectionTwo.propTypes = {}

export default HomeSectionTwo;